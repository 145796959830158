const weatherDescKo: { [key: number]: string } = {
    // Thunderstorm
    200: '가벼운 비를 동반한 천둥번개',
    201: '비를 동반한 천둥번개',
    202: '강한 비를 동반한 천둥번개',
    210: '약한 천둥번개',
    211: '천둥번개',
    212: '강한 천둥번개',
    221: '불규칙적인 천둥번개',
    230: '가벼운 이슬비를 동반한 천둥번개',
    231: '이슬비를 동반한 천둥번개',
    232: '강한 이슬비를 동반한 천둥번개',

    // Drizzle
    300: '약한 이슬비',
    301: '이슬비',
    302: '강한 이슬비',
    310: '약한 이슬비와 비',
    311: '이슬비와 비',
    312: '강한 이슬비와 비',
    313: '소나기 비와 이슬비',
    314: '강한 소나기 비와 이슬비',
    321: '소나기 이슬비',

    // Rain
    500: '약한 비',
    501: '보통 비',
    502: '강한 비',
    503: '매우 강한 비',
    504: '극심한 비',
    511: '어는 비',
    520: '약한 소나기 비',
    521: '소나기 비',
    522: '강한 소나기 비',
    531: '불규칙적인 소나기 비',

    // Snow
    600: '약한 눈',
    601: '눈',
    602: '강한 눈',
    611: '진눈깨비',
    612: '약한 소나기 진눈깨비',
    613: '소나기 진눈깨비',
    615: '약한 비와 눈',
    616: '비와 눈',
    620: '약한 소나기 눈',
    621: '소나기 눈',
    622: '강한 소나기 눈',

    // Atmosphere
    701: '안개',
    711: '연기',
    721: '실안개',
    731: '모래/먼지 소용돌이',
    741: '안개',
    751: '모래',
    761: '먼지',
    762: '화산재',
    771: '돌풍',
    781: '토네이도',

    // Clear
    800: '맑음',

    // Clouds
    801: '구름 조금',
    802: '구름 많음',
    803: '흐린 구름',
    804: '짙은 구름',
};

export default weatherDescKo;
